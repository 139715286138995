
import { ref, onMounted, onUnmounted, defineEmits, watch, Ref } from "vue";
import { defineComponent } from "vue";
import Api from "../../services/Api";
import s3 from '../../services/S3Service'
import useAlert from "../../composables/Alert";
import Button from "@/components/Button.vue"
import { Modal } from "bootstrap";
import ModalPlanilhaFiltro from "./components/ModalPlanilhaFiltro.vue";

export default defineComponent({
  name: "UploadPlanilhas",
  components: {
    Button,
    ModalPlanilhaFiltro,
  },

  setup() {

    const { showTimeAlert } = useAlert();

    const arquivo: Ref<any> = ref();
    const marca: Ref<string | null> = ref(null);
    const fileInput: Ref<any> = ref(null);
    const marcas: Ref<any> = ref([]);
    const historicoArquivos: Ref<any> = ref([]);
    const qtdArquivosSalvos: Ref<number> = ref(0)
    const file: Ref<any> = ref(null);

    const rodandoFila = ref(false)
    const alteraRodandoFila = (valor: boolean) => rodandoFila.value = valor

    const caminhoDoArquivo = "AtriaLub/planilhas/atualizacao_filtros/";



    const arquivoSelecionado: Ref<any> = ref("")
    const modalPlanilhaFiltro: Ref<any> = ref()



    const openInput = () => fileInput.value.click();

    const uploadFile = async (event: any) => {
      const file = event.target.files[0]
      arquivo.value = file
    };


    function dropHandler(e) {

      stopDefault(e)

      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...e.dataTransfer.items].forEach((item, i) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            file.value = item.getAsFile();
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...e.dataTransfer.files].forEach((fileParam, i) => {
          file.value = fileParam;
        });
      }

      arquivo.value = file.value
    }
    
    

    function stopDefault(e) {
      // Prevent default behavior (Prevent file from being opened)
      e.stopPropagation();
      e.preventDefault();
    }


    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    async function getMarcas() {
      const { data } = await Api.post("getMarcasFiltros")
      marcas.value = data
    }

    function limparArquivo() {
      arquivo.value = null
    }

    async function enviarPlanilha() {

      arquivo.value.idMarca = marca.value;

      const timeStamp = (new Date()).getTime();

      const caminhoAws = caminhoDoArquivo + timeStamp + "-" + arquivo.value.name

      s3.upload({ Key: `${caminhoDoArquivo}${timeStamp}-${arquivo.value.name}`, Body: arquivo.value, ACL: "public-read" }, async (error, data) => {

        if(!data.key) return showTimeAlert("Planilha enviada com sucesso", 'error')

        const retorno = await Api.post("processaPlanilhaFiltro", { pathArquivo: data.key, nome: `${timeStamp}-${arquivo.value.name}`, idMarca: arquivo.value.idMarca })
        
        showTimeAlert("Planilha enviada com sucesso")
  
        pegarListaDePlanilhas()
  
        console.log('retorno', retorno);

        arquivo.value = null;

        marca.value = null;

      });


    }

    function pegarListaDePlanilhas() {

      s3.listObjects({ Bucket: 'varejo-storage', Delimiter: '/', Prefix: 'AtriaLub/planilhas/atualizacao_filtros/', }, async(_, data) => {

        const lista: any[] = [];

        data.Contents.sort((a, b) => b.LastModified - a.LastModified)

        const { filas } = (await Api.get("getFilas")).data || []

        data.Contents.forEach(i =>{

          const item = { 

            nome: i.Key.replace('AtriaLub/planilhas/atualizacao_filtros/', ''),
            status: 'concluído',
            erro: false,
            detalheErro: '',
            data: formatData(i.LastModified),
            pasta: i.Key,

          }

          filas.forEach(j => {

            if(item.nome === j.nome.replace('tabela_filtros_', '')) {

              item.erro = j.erro;
              item.status = j.status;
              item.detalheErro = j.detalheErro;

            }

            
          });

          lista.push(item)


        })

        
        
        
        
        
        
        
        historicoArquivos.value = lista.slice(0, 10)

        console.log('filas', historicoArquivos.value)

        qtdArquivosSalvos.value = data.Contents.length




      })
    }






    function formatData(data: Date) {

      const dia = data.getDate().toString()

      const diaF = (dia.length == 1) ? '0' + dia : dia

      const mes = (data.getMonth() + 1).toString() //+1 pois no getMonth Janeiro começa com zero.

      const mesF = (mes.length == 1) ? '0' + mes : mes

      const anoF = data.getFullYear();

      return diaF + "/" + mesF + "/" + anoF;
    }










    ///////////////////// MODAL PRODUTO LOG /////////////////////

    function abreModal(arquivo?: any) {

      arquivoSelecionado.value = arquivo || {}
      modalPlanilhaFiltro.value.show()

    }

    async function fechaModal() {

      arquivoSelecionado.value = ""
      modalPlanilhaFiltro.value.hide()

    }

    ///////////////////// MODAL PRODUTO LOG /////////////////////















    watch(() => rodandoFila.value, async () => { pegarListaDePlanilhas() }, { deep: true })

    onMounted(async() => {

      modalPlanilhaFiltro.value = new Modal(document.querySelector(`#ModalPlanilhaFiltro`));

      getMarcas()
      pegarListaDePlanilhas()

    })

    return {

      dropHandler,
      stopDefault,
      arquivo,
      limparArquivo,
      enviarPlanilha,
      marcas,
      marca,
      historicoArquivos,
      formatBytes,
      openInput,
      uploadFile,
      fileInput,
      formatData,
      history,
      qtdArquivosSalvos,
      abreModal,
      fechaModal,
      arquivoSelecionado,
      rodandoFila,
      alteraRodandoFila

    }
  },
});
